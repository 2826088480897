import Image from 'next/image';

import { useResponsive } from 'hooks';

import styles from './Section2.module.scss';
import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const Section2 = () => {
  const { isMobile } = useResponsive();

  return (
    <section>
      <SectionTitle>
        <SectionCaption number={1} title={'About TradingBank'} />
        <h2>트레이딩뱅크는</h2>
      </SectionTitle>
      <div className={styles.featureCardWrapper}>
        <div className={styles.featureCard}>
          <Image
            src={'/images/home-section2-1.png'}
            width={isMobile ? 318 : 400}
            height={isMobile ? 318 : 400}
          />
          <h3>간편합니다.</h3>
          <p>거래소 연동만으로 추가적인 절차 없이,</p>
          <p> 모든 서비스를 이용하실 수 있습니다.</p>
        </div>
        <div className={styles.featureCard}>
          <Image
            src={'/images/home-section2-2.png'}
            width={isMobile ? 318 : 400}
            height={isMobile ? 318 : 400}
          />
          <h3>합리적입니다.</h3>
          <p>과금이 필요하지 않습니다.</p>
          <p> 부담 없이 이용하실 수 있습니다.</p>
        </div>
        <div className={styles.featureCard}>
          <Image
            src={'/images/home-section2-3.png'}
            width={isMobile ? 318 : 400}
            height={isMobile ? 318 : 400}
          />
          <h3>똑똑합니다.</h3>
          <p>전문 퀀트 트레이더의 전략대로 투자할 수 있습니다.</p>
          <p>모든 정보는 투명하게 공개합니다.</p>
        </div>
      </div>
    </section>
  );
};

export default Section2;
