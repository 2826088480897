import Image from 'next/image';

import { useResponsive } from 'hooks';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import styles from './Section5.module.scss';
import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const Section5 = () => {
  const { isMobile } = useResponsive();

  const handleIOSAppDownloadClick = () => {
    trackEvent(amplitudeEventNames.BTN_APP_DOWNLOAD, { value: 'apple' });
  };

  const handleAndroidAppDownloadClick = () => {
    trackEvent(amplitudeEventNames.BTN_APP_DOWNLOAD, { value: 'google' });
  };

  return (
    <section>
      <SectionTitle>
        <SectionCaption number={4} title={'Mobile App'} />
        <h2>
          모바일로 언제 어디서나 <br />
          수익률 관리
        </h2>
      </SectionTitle>
      <div className={styles.mobileContentWrapper}>
        <div className={styles.iphone}>
          <Image
            src={'/images/home-section5-iphone.png'}
            width={isMobile ? 260 : 320}
            height={isMobile ? 511.5 : 629.5}
          />
        </div>
        <div>
          <div className={styles.mobileTextWrapper}>
            <b>트레이딩뱅크 모바일 출시</b>
            <p>
              {`언제 어디서든 간편하게.
              이제 모바일에서 내 트레이딩로봇의 수익률을 확인하고, 상태를 관리하세요.`}
            </p>
          </div>
          <div className={styles.storeWrapper}>
            <a
              href="https://apps.apple.com/kr/app/%ED%8A%B8%EB%A0%88%EC%9D%B4%EB%94%A9%EB%B1%85%ED%81%AC-%ED%80%80%ED%8A%B8-%ED%8A%B8%EB%A0%88%EC%9D%B4%EB%94%A9-%ED%94%8C%EB%9E%AB%ED%8F%BC/id6473887308"
              target="_blank"
              rel="noreferrer"
              onClick={handleIOSAppDownloadClick}
              className={styles.appStoreButton}
            >
              <Image
                src={'/images/home-section5-appstore.png'}
                width={isMobile ? 24 : 60}
                height={isMobile ? 24 : 60}
              />
              <span>
                App Store에서 <br />
                다운로드
              </span>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=io.tradingbank.app"
              target="_blank"
              rel="noreferrer"
              onClick={handleAndroidAppDownloadClick}
              className={styles.googleStoreButton}
            >
              <Image
                src={'/images/home-section5-googlestore.png'}
                width={isMobile ? 24 : 60}
                height={isMobile ? 24 : 60}
              />
              <span>
                Google Play에서 <br />
                다운로드
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
