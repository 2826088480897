import Image from 'next/image';

import { useRouter } from 'next/router';

import { routes } from 'constants/';
import { useResponsive } from 'hooks';
import ArrowPrimary32 from 'images/icons/svgs/arrow-circle-right-primary32.svg';

import { useSession } from 'next-auth/react';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import Button from 'components/Button';

import styles from './Section3.module.scss';
import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const Section3 = () => {
  const router = useRouter();
  const { isMobile } = useResponsive();
  const { data: session } = useSession();

  const createStrategyClick = () => {
    trackEvent(amplitudeEventNames.BTN_START_STRATEGY);
    router.push(routes.backtestingRoute);
  };

  const createBacktestingClick = () => {
    trackEvent(amplitudeEventNames.BTN_START_BACKTEST);
    router.push(routes.backtestingRoute);
  };

  const alertPreparingClick = () => {
    trackEvent(amplitudeEventNames.BTN_START_TRADING);
    router.push(routes.tradingrobotRoute(session?.username || 'username'));
  };

  return (
    <section>
      <SectionTitle>
        <SectionCaption number={2} title={'Service Function'} />
      </SectionTitle>
      <div className={styles.section3Container}>
        <div className={styles.section3Card}>
          <Image
            src={'/images/home-section3-1.png'}
            width={isMobile ? 318 : 480}
            height={isMobile ? 212 : 320}
          />
          <div className={styles.section3TextWrapper}>
            <b>논코딩 알고리즘</b>
            <h3>상상 속 투자 전략을 현실로</h3>

            <p>
              코딩, 필요 없습니다.
              <br />
              클릭만으로 유연하게 트레이딩로봇을 만들어보세요.
            </p>

            {!isMobile && (
              <Button
                theme="primary2"
                size="none"
                extraClassNames={[styles.buttonH40]}
                onClick={createStrategyClick}
              >
                전략 만들러 가기
                <ArrowPrimary32 />
              </Button>
            )}
          </div>
        </div>

        <div className={styles.section3Card}>
          <Image
            src={'/images/home-section3-2.png'}
            width={isMobile ? 318 : 480}
            height={isMobile ? 212 : 320}
          />
          <div className={styles.section3TextWrapper}>
            <b>압도적인 백테스팅 성능</b>
            <h3>10초만에 수익률 검증</h3>
            <p>
              투자하기 전, 과거 데이터 기반 시뮬레이션을 통해
              <br />
              클릭만으로 유연하게 트레이딩로봇을 만들어보세요.
            </p>
            {!isMobile && (
              <Button
                theme="primary2"
                size="none"
                extraClassNames={[styles.buttonH40]}
                onClick={createBacktestingClick}
              >
                전략 검증하기
                <ArrowPrimary32 />
              </Button>
            )}
          </div>
        </div>

        <div className={styles.section3Card}>
          <Image
            src={'/images/home-section3-3.png'}
            width={isMobile ? 318 : 480}
            height={isMobile ? 212 : 320}
          />

          <div className={styles.section3TextWrapper}>
            <b>신뢰도 높은 로보어드바이저</b>
            <h3>알아서, 자동으로 투자</h3>
            <p>
              24시간 365일, 빠르고 정확하게 전략대로 투자합니다.
              <br />
              실시간 작동 내역까지 모니터링해보세요.
            </p>

            {!isMobile && (
              <Button
                theme="primary2"
                size="none"
                extraClassNames={[styles.buttonH40]}
                onClick={alertPreparingClick}
              >
                자동 투자하러 가기
                <ArrowPrimary32 />
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
