import Image from 'next/image';

import { useRouter } from 'next/router';

import { routes } from 'constants/';
import ArrowBlack32 from 'images/icons/svgs/arrow-circle-right-black32.svg';

import { useSession } from 'next-auth/react';

import { Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import Button from 'components/Button';

import styles from './Section1.module.scss';
import { SectionTitle } from './SectionTitle';

import 'swiper/swiper-bundle.css';
import 'swiper/css';

interface Gif {
  url: string;
  title: string;
}
interface GifSwiperProps {
  gifs: Gif[];
}
const gifs = [
  {
    url: '/images/home-section1-gif1.gif',
    title: '투자 전략 생성',
  },
  {
    url: '/images/home-section1-gif2.gif',
    title: ' 투자 성과 시뮬레이션',
  },
  {
    url: '/images/home-section1-gif3.gif',
    title: ' 나만의 투자 전략으로 자동 투자',
  },
  {
    url: '/images/home-section1-gif4.gif',
    title: '크리에이터의 투자 전략으로 자동 투자',
  },
];
const GifSwiper = ({ gifs }: GifSwiperProps) => {
  return (
    <Swiper
      initialSlide={1}
      slidesPerView={1}
      spaceBetween={20}
      loop={true}
      breakpoints={{
        768: {
          slidesPerView: 1.7,
          spaceBetween: 80,
        },
      }}
      centerInsufficientSlides={true}
      allowTouchMove={true}
      centeredSlides={true}
      observer={true}
      observeSlideChildren={true}
      centeredSlidesBounds={true}
      modules={[Autoplay, EffectCoverflow]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      effect={'coverflow'}
      coverflowEffect={{
        rotate: 0,
        depth: 100,
        stretch: 0,
        modifier: 1,
        scale: 1,
        slideShadows: false,
      }}
    >
      {gifs.map((gif, index) => (
        <SwiperSlide key={gif.url} className={styles.slide}>
          <Image
            src={gif.url}
            alt={`GIF ${index}`}
            width={1020}
            height={574}
            className={styles.slideImage}
          />
          <div className={styles.label}>{gif.title}</div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const Section1 = () => {
  const router = useRouter();
  const { status } = useSession();

  const handleSignInClick = () => {
    trackEvent(amplitudeEventNames.BTN_SESSION_START);

    if (status === 'authenticated') {
      router.push(routes.homeRoute);
    }

    if (status === 'unauthenticated') {
      router.push(routes.signInRoute);
    }
  };

  return (
    <section className={styles.section1}>
      <SectionTitle extraClassName={styles.titleWrapper}>
        <h1>
          디지털 자산
          <br /> <b>자동 투자</b>의 시작
        </h1>
        <div>
          <p>
            전략을 만들고 전략 크리에이터를 팔로우하는 플랫폼, 트레이딩뱅크.
          </p>
          <p>누구나 근거 있게 투자할 수 있는 생태계를 만들어갑니다.</p>
        </div>
      </SectionTitle>
      <Button
        theme="primary1"
        size="none"
        extraClassNames={[styles.button]}
        onClick={handleSignInClick}
      >
        <span>바로 시작하기</span>
        <ArrowBlack32 />
      </Button>
      <div>
        <GifSwiper gifs={gifs} />
      </div>
    </section>
  );
};

export default Section1;
