import { PropsWithChildren } from 'react';

import Footer from 'components/Footer/Footer';
import { LandingHeader } from 'components/Layouts/Header/LandingHeader';

export const LandingLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <LandingHeader />
      {children}
      <Footer />
    </>
  );
};
