import Image from 'next/image';

import styles from './Section8.module.scss';
import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const Section8 = () => {
  const toggleChannelService = () => {
    window.ChannelIO?.('showMessenger');
  };
  return (
    <section>
      <SectionTitle>
        <SectionCaption number={7} title={'Contact'} />
        <h2>
          더 궁금한 게 있다면,
          <br />
          언제든 연락주세요.
        </h2>
        <p>트레이딩뱅크는 24시간 열려있습니다.</p>
      </SectionTitle>
      <div className={styles.contactButtonWrapper}>
        <button
          onClick={toggleChannelService}
          className={styles.contactButtonContainer}
        >
          <div className={styles.contactButton}>
            <span>채널톡 문의하기</span>
            <Image
              src="/icons/inquiry-icon.png"
              alt="channeltalk-icon"
              width={24}
              height={24}
            />
          </div>
        </button>

        <div className={styles.contactButtonContainer}>
          <a
            href="https://open.kakao.com/o/gOdbpJqf"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.contactButton}>
              <span>오픈 채팅방 입장하기</span>
              <Image
                src="/icons/openchat-icon.png"
                alt="openchat"
                width={24}
                height={24}
              />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Section8;
