import { useState } from 'react';

import { useResponsive } from 'hooks';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import { fireAlert } from 'features/utils';

import Button from 'components/Button';

import styles from './Section7.module.scss';
import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const WebEmailContainer = () => {
  const [value, setValue] = useState('');
  const handleSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (value === '') {
      fireAlert({ text: '이메일을 입력해주세요.' });
      return;
    }
    if (!emailRegex.test(value)) {
      fireAlert({ text: '이메일 형식이 올바르지 않습니다.' });
      return;
    }
    trackEvent(amplitudeEventNames.BTN_UPDATE_SUBSCRIBE, { email: value });
    fireAlert({
      text: '제출되었습니다.',
      thenCallback: () => {
        setValue('');
      },
    });
  };

  return (
    <div className={styles.webEmailInput}>
      <input
        type="email"
        placeholder="이메일 주소를 입력해주세요"
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <Button
        theme="primary2"
        size="large"
        extraClassNames={[styles.buttonH40]}
        onClick={handleSubmit}
      >
        업데이트 소식을 받아볼게요
      </Button>
    </div>
  );
};

const MobileEmailContainer = () => {
  const [value, setValue] = useState('');
  const handleSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (value === '') {
      fireAlert({ text: '이메일을 입력해주세요.' });
      return;
    }
    if (!emailRegex.test(value)) {
      fireAlert({ text: '이메일 형식이 올바르지 않습니다.' });
      return;
    }
    trackEvent(amplitudeEventNames.BTN_UPDATE_SUBSCRIBE, { email: value });
    fireAlert({
      text: '제출되었습니다.',
      thenCallback: () => {
        setValue('');
      },
    });
  };
  return (
    <div className={styles.mobileEmailInput}>
      <input
        placeholder="이메일 주소를 입력해주세요"
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <Button
        size="large"
        theme="primary2"
        extraClassNames={[styles.buttonH40]}
        onClick={handleSubmit}
      >
        업데이트 소식을 받아볼게요
      </Button>
    </div>
  );
};

const Section7 = () => {
  const { isMobile } = useResponsive();

  return (
    <section className={styles.section7}>
      <SectionTitle extraClassName={styles.titleWrapper}>
        <SectionCaption number={6} title={'Mission'} />
        <h2>
          안전한 투자 문화를 <br />
          만듭니다
        </h2>
        <div className={styles.sectionComment}>
          <p>
            지금까지 우리는 큰 시장의 흐름에 기댈 수 밖에 없었습니다. <br />
            그러나 우리는 이런 시장의 흐름에 대항할 수 있다고 믿습니다. <br />
            누구나 데이터를 통해 객관적으로 투자하는 세상을 만듭니다.
          </p>
          <strong>트레이딩뱅크에서 그 첫번째 여정을 시작합니다.</strong>

          <a
            href="https://timepercentcorp.com/blog/patchnote"
            target="_blank"
            rel="noreferrer"
            className={styles.lastUpdate}
          >
            지난 업데이트 소식 보러가기
          </a>
        </div>
      </SectionTitle>
      {isMobile ? <MobileEmailContainer /> : <WebEmailContainer />}
    </section>
  );
};

export default Section7;
