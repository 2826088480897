import styles from './SectionCaption.module.scss';
interface SectionTitleProps {
  number: number;
  title: string;
}
export const SectionCaption = ({ number, title }: SectionTitleProps) => {
  return (
    <div className={styles.sectionCaptionWrapper}>
      <div className={styles.sectionCaption}>
        <span>{`0${number}`}</span>
        <span>{title}</span>
      </div>
    </div>
  );
};
