import Section1 from 'widgets/Landing/Section1';
import Section2 from 'widgets/Landing/Section2';
import Section3 from 'widgets/Landing/Section3';
import Section4 from 'widgets/Landing/Section4';
import Section5 from 'widgets/Landing/Section5';
import Section6 from 'widgets/Landing/Section6';
import Section7 from 'widgets/Landing/Section7';
import Section8 from 'widgets/Landing/Section8';

import { LandingLayout } from 'components/Layouts/LandingLayout';

import styles from 'pages/Landing.module.scss';

const Landing = () => {
  return (
    <LandingLayout>
      <main className={styles.main}>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
      </main>
    </LandingLayout>
  );
};

export default Landing;
