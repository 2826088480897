import Image from 'next/image';

import { useResponsive } from 'hooks';

import styles from './Section6.module.scss';
import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const Section6 = () => {
  const { isMobile } = useResponsive();

  return (
    <section className={styles.section6}>
      <SectionTitle>
        <SectionCaption number={5} title={'Partnership'} />
        <h2>
          트레이딩뱅크와 <br />
          함께하는 파트너
        </h2>
      </SectionTitle>
      {isMobile ? (
        <Image
          src={'/images/home-partners-mobile.png'}
          width={350}
          height={136}
        />
      ) : (
        <Image
          src={'/images/home-partners-web.png'}
          width={1280}
          height={400}
        />
      )}
    </section>
  );
};

export default Section6;
