import Link from 'next/link';

import { useRouter } from 'next/router';

import { useState } from 'react';

import { landingMenuItems, routes } from 'constants/';
import { useClickAway, useResponsive } from 'hooks';

import HamburgerIcon from 'images/icons/svgs/hamburger.svg';

import { useSession } from 'next-auth/react';

import Button from 'components/Button';

import { Header } from './Header';
import styles from 'components/Layouts/Header/LandingHeader.module.scss';
const LandingMobileMenu = () => {
  return (
    <ul className={styles.mobileMenuWrapper}>
      {landingMenuItems.map(({ name, to }) => (
        <li key={name}>
          <Link href={to.toString()}>
            <a target="_blank">{name}</a>
          </Link>
        </li>
      ))}
    </ul>
  );
};
const LandingRightContents = () => {
  const { isMobile } = useResponsive();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hamburgerRef = useClickAway(() => {
    setIsMenuOpen(false);
  });
  const router = useRouter();
  const { status } = useSession();

  const handleStartClick = () => {
    if (status === 'authenticated') {
      router.push(routes.homeRoute);
    }

    if (status === 'unauthenticated') {
      router.push(routes.signInRoute);
    }
  };
  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      {isMobile ? (
        <div ref={hamburgerRef} className={styles.iconWrapper}>
          <HamburgerIcon
            style={{ cursor: 'pointer' }}
            onClick={toggleMobileMenu}
          />
          {isMenuOpen && <LandingMobileMenu />}
        </div>
      ) : (
        <ul className={styles.rightLandingButtons}>
          <>
            {landingMenuItems.map(({ name, to }) => (
              <li key={name} className={styles.menuItem}>
                <Link href={to.toString()}>
                  <a target="_blank">{name}</a>
                </Link>
              </li>
            ))}
            <li className={styles.menuItem}>
              <Button onClick={handleStartClick} theme="primary1" size="medium">
                바로 시작하기
              </Button>
            </li>
          </>
        </ul>
      )}
    </div>
  );
};

export const LandingHeader = () => {
  return <Header items={[]} rightElement={<LandingRightContents />} />;
};
