import { PropsWithChildren } from 'react';

import clsx from 'clsx';

import styles from './SectionTitle.module.scss';
interface SectionTitleProps {
  extraClassName?: string;
}

export const SectionTitle = ({
  children,
  extraClassName,
}: PropsWithChildren<SectionTitleProps>) => {
  return (
    <div className={clsx(styles.sectionTitle, extraClassName)}>{children}</div>
  );
};
