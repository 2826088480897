import 'swiper/swiper-bundle.css';
import 'swiper/css';

import Image from 'next/image';

import { useRouter } from 'next/router';

import { routes } from 'constants/';
import { useResponsive } from 'hooks';
import { Swiper, SwiperSlide } from 'swiper/react';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import { SectionCaption } from './SectionCaption';
import { SectionTitle } from './SectionTitle';

const imgs = [
  '/images/home-section4-strategy1.png',
  '/images/home-section4-strategy2.png',
  '/images/home-section4-strategy3.png',
];

const Section4 = () => {
  const router = useRouter();
  const { isMobile } = useResponsive();

  const handleImageClick = () => {
    trackEvent(amplitudeEventNames.BTN_START_SUBSCRIBE);
    router.push(routes.marketplaceRoute);
  };

  return (
    <section>
      <SectionTitle>
        <SectionCaption number={3} title={'Market'} />
        <h2>로봇 마켓을 구경해보세요 </h2>
        <div>
          <p>
            <b>전문 퀀트 트레이더</b>가 만들어 수익률까지 검증한 트레이딩로봇을
            팔로우해보세요.
          </p>
          <p>
            트레이딩뱅크에서는 <b>별도의 비용 결제 없이</b> 이용 가능합니다.
          </p>
        </div>
      </SectionTitle>

      {isMobile ? (
        <Swiper
          initialSlide={1}
          slidesPerView={1.2}
          spaceBetween={8}
          centerInsufficientSlides={true}
          allowTouchMove={true}
          centeredSlides={true}
          observer={true}
          observeSlideChildren={true}
          centeredSlidesBounds={true}
        >
          {imgs.map((url, index) => (
            <SwiperSlide key={url}>
              <Image
                src={url}
                alt={`Image ${index}`}
                width={309}
                height={176}
                onClick={handleImageClick}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Image
          src={'/images/home-section4-web.png'}
          width={1403}
          height={260}
          onClick={handleImageClick}
          style={{ cursor: 'pointer' }}
        />
      )}
    </section>
  );
};

export default Section4;
